<template>
    <div>
        <div class="flex justify-end mb-3">
            <Button text="Add Contact" type="primary" @click="addNewContact" />
        </div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.fetching">
            <Loader />
        </div>
        <div v-else class="bg-white contactContainer">
            <div class="HeadingPart bg-primary-300">Contact</div>
            <FormulateForm>
                <div class="flex flex-col gap-6 p-4">
                    <!-- Primary Contact -->
                    <div class="contactDetails">
                        <h1 class="text-gray-700 p-3 font-semibold contact bg-primary-300 text-lg ">Primary Contact</h1>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Name" placeholder="Name" type="text"
                                    validation="required" v-model="contact.primary_contact.name"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Position" placeholder="Position" type="text"
                                    v-model="contact.primary_contact.position"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :validation="checkPositionVal"
                                    :validation-messages="{ matches: 'Position cannot contain number or any special characters' }" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone "
                                    type="text" v-model="contact.primary_contact.phone"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber" />
                                <!-- <FormulateInput class="w-full" label="Email" validation="required" placeholder="Email"
                                    type="email" v-model="contact.primary_contact.email"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :disabled="isEmailFilled" /> -->
                                    <div>
                                        <label>Email</label>
                                        <span class="text-red-500 ml-1 text-lg leading-3 mr-auto">*</span>
                                        <div>
                                    <input type="email" v-model="contact.primary_contact.email"  placeholder="Email" class="w-full input email-inp"
                                    :class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :disabled="isEmailFilled" />
                                </div>
                                </div>
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class=" w-full flex justify-between">
                                    <FormulateInput class="w-full" label="Received client action alert emails"
                                        placeholder="Received client action alert emails" type="checkbox"
                                        v-model="contact.primary_contact.receive_client_action_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Received final report alert emails"
                                        placeholder="Received final report alert emails" type="checkbox"
                                        v-model="contact.primary_contact.receive_final_report_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea"
                                    v-model="contact.primary_contact.note"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                    </div>
                    <!-- Accounts Contact -->
                    <div class="contactDetails">
                        <h1 class="text-gray-700 p-3 font-semibold text-lg bg-primary-300 contact">Accounts Contact</h1>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Name" placeholder="Name" type="text"
                                    :validation="checkReqCondition" v-model="contact.accounts_contact.name"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Position" placeholder="Position" type="text"
                                    v-model="contact.accounts_contact.position"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :validation="checkPositionVal"
                                    :validation-messages="{ matches: 'Position cannot contain number or any special characters' }" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Phone" :validation="checkReqCondition"
                                    placeholder="Phone " type="text" v-model="contact.accounts_contact.phone"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber" />
                                <FormulateInput class="w-full" label="Email" :validation="checkReqCondition"
                                    placeholder="Email" type="email" v-model="contact.accounts_contact.email"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class=" w-full flex justify-between">
                                    <FormulateInput class="w-full" label="Received client action alert emails"
                                        placeholder="Received client action alert emails" type="checkbox"
                                        v-model="contact.accounts_contact.receive_client_action_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Received final report alert emails"
                                        placeholder="Received final report alert emails" type="checkbox"
                                        v-model="contact.accounts_contact.receive_final_report_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea"
                                    v-model="contact.accounts_contact.note"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                    </div>

                    <!-- Addition Contacts -->
                    <hr v-if="contact.additional_contacts?.length > 0" />
                    <div class="contactDetails" v-for="(additionalContact, index) in contact.additional_contacts"
                        :key="index">
                        <div class="contact flex justify-between text-center align-center">
                            <h1 class="text-gray-700 p-3 font-semibold">Accounts Contact {{ index + 1 }}</h1>
                            <div class="deleteForm p-2 mr-4 my-3 cursor-pointer" @click="deleteCurrentContact(index)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path
                                        d="M2.73219 4.42518V13.9574C2.73219 15.0855 3.71567 16 4.92806 16H11.5162C12.729 16 13.7125 15.0855 13.7125 13.9574V4.42518H2.73219ZM5.6628 13.8533C5.6628 14.1012 5.4985 14.3017 5.29568 14.3017H4.92808C4.72502 14.3017 4.56073 14.1012 4.56073 13.8533L4.56073 6.23063C4.56073 5.99174 4.71486 5.80095 4.90733 5.78699H5.31576C5.5082 5.80095 5.66235 5.99175 5.66235 6.23063V13.8533H5.6628V13.8533ZM8.95682 13.8533C8.95682 14.1012 8.79253 14.3017 8.58968 14.3017H7.85474C7.65216 14.3017 7.48762 14.1012 7.48762 13.8533V6.23063C7.48762 5.99174 7.64179 5.80095 7.83422 5.78699H8.60979C8.80249 5.80095 8.95639 5.99175 8.95639 6.23063V13.8533H8.95682V13.8533ZM11.8837 6.23063V13.8535C11.8837 14.1014 11.7195 14.3019 11.5162 14.3019H11.1488C10.9462 14.3019 10.7819 14.1014 10.7819 13.8535V6.23063C10.7819 5.99174 10.9358 5.80095 11.1285 5.78699H11.5367C11.7298 5.80095 11.8837 5.99174 11.8837 6.23063ZM13.7125 1.702H2.73219C2.32791 1.702 2 2.00699 2 2.38279V2.72318C2 3.09942 2.32791 3.40399 2.73219 3.40399H13.7125C14.1165 3.40399 14.4444 3.09942 14.4444 2.72318V2.38279C14.4444 2.00699 14.117 1.702 13.7125 1.702Z"
                                        fill="#FF7777" />
                                    <path
                                        d="M7.3588 0H9.21614C9.62067 0 9.94812 0.304991 9.94812 0.680796V1.02119C9.94812 1.39742 9.62021 1.702 9.21614 1.702H7.3588C6.95452 1.702 6.62661 1.39742 6.62661 1.02119V0.680796C6.62661 0.304991 6.95452 0 7.3588 0Z"
                                        fill="#FF7777" />
                                </svg>
                            </div>
                        </div>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Name" placeholder="Name" type="text"
                                    validation="required" v-model="additionalContact.name"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Position" placeholder="Position" type="text"
                                    v-model="additionalContact.position"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone "
                                    type="text" v-model="additionalContact.phone"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber" />
                                <FormulateInput class="w-full" label="Email" validation="required" placeholder="Email"
                                    type="email" v-model="additionalContact.email"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class=" w-full flex justify-between">
                                    <FormulateInput class="w-full" label="Received client action alert emails"
                                        placeholder="Received client action alert emails" type="checkbox"
                                        v-model="additionalContact.receive_client_action_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Received final report alert emails"
                                        placeholder="Received final report alert emails" type="checkbox"
                                        v-model="additionalContact.receive_final_report_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea"
                                    v-model="additionalContact.note"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                    </div>
                </div>
            </FormulateForm>
        </div>
        <div class="flex mt-4 w-full justify-between bg-white p-4 buttonPanel">
            <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
            <Button :loader="loadingState.submit" :text="$route.params.tenant_id ? 'Update & Next' : 'Save & Next'"
                type="primary" @click="handleSubmit" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import Loader from "@shared/loader";
import axios from "@/axios";
import { isIntegerNumber } from "@/plugins/functions";
import { fetchClientContacts, getTenantContacts, validMail } from "../services";

export default {
    name: "client-admin-contacts",
    components: {
        Button,
        Loader,
    },
    props: {},
    data() {
        return {
            contact: {
                primary_contact: {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                },
                accounts_contact: {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                },
                additional_contacts: [],
                key: "contact"
            },
            clientId: null,
            loadingState: {
                submit: false,
                fetching: false,
            },
            isEmailFilled: false,
        };
    },
    computed: {
        checkReqCondition() {
            if (this.checkEmptyData) {
                return 'required';
            } else {
                return '';
            }
        },
        checkEmptyData() {
            return (
                this.contact.accounts_contact?.name !== '' ||
                this.contact.accounts_contact?.position !== '' ||
                this.contact.accounts_contact?.phone !== '' ||
                this.contact.accounts_contact?.email !== '' ||
                (this.contact.primary_contact && (
                    this.contact.primary_contact.name !== '' ||
                    this.contact.primary_contact.position !== '' ||
                    this.contact.primary_contact.phone !== '' ||
                    this.contact.primary_contact.email !== ''
                ))
            );
        },
        checkPositionVal() {
            const matchesRegex = /^[a-zA-Z\s]+$/;

            if (
                this.checkEmptyData &&
                this.contact.accounts_contact.position !== '' &&
                !matchesRegex.test(this.contact.accounts_contact.position)
            ) {
                return 'matches:/^[a-zA-Z\\s]+$/';
            }

            if (
                this.checkEmptyData &&
                this.contact.primary_contact.position !== '' &&
                !matchesRegex.test(this.contact.primary_contact.position)
            ) {
                return 'matches:/^[a-zA-Z\\s]+$/';
            }
            return '';
        }

    },
    async mounted() {
        if (this.$route.params?.tenant_id) {
            this.loadingState.fetching = true
            const { data } = await getTenantContacts(this.$route.params.tenant_id)
            const { contact } = data;
            this.contact = {
                primary_contact: contact?.primary_contact ? contact?.primary_contact : this.contact.primary_contact,
                accounts_contact: contact?.accounts_contact ? contact?.accounts_contact : this.contact.accounts_contact,
                additional_contacts: contact?.additional_contacts ? contact?.additional_contacts : this.contact.additional_contacts
            }
            this.isEmailFilled = contact?.primary_contact?.email
            this.contact["key"] = "contact"
            this.$emit('enableAllSelection')
            this.loadingState.fetching = false
        }
        await this.getClientContacts();
    },
    methods: {
        isIntegerNumber,
        addNewContact() {
            this.contact?.additional_contacts?.push({
                name: "",
                position: "",
                phone: "",
                email: "",
                receive_client_action_alert: false,
                receive_final_report_alert: false,
                note: "",
            })
        },
        deleteCurrentContact(currentIndex) {
            this.contact?.additional_contacts?.splice(currentIndex, 1);
        },
        handleErros(key) {
            const { primary_contact, accounts_contact, additional_contacts } = this.contact;
            let contactError = false
            if (key == 'requiredValues') {
                if (primary_contact?.name ||
                    primary_contact?.phone ||
                    primary_contact?.email
                ) {
                    if (!primary_contact?.name ||
                        !primary_contact?.phone ||
                        !primary_contact?.email
                    ) {
                        contactError = true
                    }

                }
                if (accounts_contact?.name ||
                    accounts_contact?.phone ||
                    accounts_contact?.email) {
                    {
                        if (!accounts_contact?.name ||
                            !accounts_contact?.phone ||
                            !accounts_contact?.email) {
                            contactError = true
                        }
                    }
                }
            } else if (key == 'validMails') {
                if (primary_contact?.email || primary_contact?.name || primary_contact?.phone) {
                    if (!validMail(primary_contact?.email)) {
                        contactError = true;
                    }
                }
                if (additional_contacts?.email || additional_contacts?.name || additional_contacts?.phone) {
                    if (!validMail(additional_contacts?.email)) {
                        contactError = true;
                    }
                }

            }
            if (key == 'requiredValues') {
                let invalidAddress = additional_contacts?.find(contData => !contData?.name || !contData?.phone || !contData?.email)
                if (invalidAddress) {
                    contactError = true
                }
            } else if (key == 'validMails') {
                let invalidAddress = additional_contacts?.find(contData => !validMail(contData?.email))
                if (invalidAddress) {
                    contactError = true
                }
            }
            return contactError;
        },
        async handleSubmit() {
            this.loadingState.submit = true
            const positionValMessages = this.checkPositionVal;

            if (positionValMessages) {
                this.$toast.error('Please enter a valid Position');
                this.loadingState.submit = false;
                return;
            }
            if (this.handleErros('requiredValues')) {
                this.$toast.error(`Please fill all the required fields`);
                this.loadingState.submit = false
            } else if (this.handleErros('validMails')) {
                this.$toast.error('Please enter a valid mail');
                this.loadingState.submit = false
            } else {
                this.$emit('handleNextSection', this.contact)
                this.loadingState.submit = false
            }
        },
        async getClientContacts() {
            this.loadingState.fetching = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await fetchClientContacts(this.clientId);
                    this.contact = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.fetching = false;
        },

        convertResponse(contacts) {
            if (contacts.length > 0) {
                let empty_contact = {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                }
                let primary_contact = contacts?.find((el) => el.contact_type === "Primary Contact") || empty_contact;
                let accounts_contact = contacts?.find((el) => el.contact_type === "Accounts Contact") || empty_contact;
                let additional_contacts = contacts?.filter((el) => el.contact_type === "Additional Contact");
                let new_contacts = {
                    primary_contact,
                    accounts_contact,
                    additional_contacts,
                };
                return new_contacts;
            } else {
                return this.contact;
            }
        },
        async removeOptionalContact(contacts) {
            let contact = contacts[0];
            if (contact && contact.id) {
                let url = `/client/${this.clientId}/contact/${contact.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message || "Removed!");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to remove!");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.contactContainer {
    border-radius: 10px;
}

.HeadingPart {
    // background-color: #99CBFF;
    padding: 10px;
    border-radius: 10px 10px 0px 0px;
    color: #000;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.infoHeadings {
    color: #282828;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.email-inp{
    border:1px solid #8080804a;
    height: 37px;
    margin-top: 8px;
}

.contact {
    border-radius: 12px 12px 0px 0px;
    // background: #B4D6F9;
    color: #282828;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contactDetails {
    border-radius: 12px;
    border: 1px solid #DDD;
}

.deleteForm {
    border-radius: 8px;
    background: #FFF;
}

.buttonPanel {
    position: absolute;
    left: 0;
    // bottom: 0;
}
</style>
