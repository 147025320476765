var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"configComponent"},[(_vm.loadingState.client)?_c('div',{staticClass:"bg-white p-6 rounded-lg py-24 flex items-center justify-center"},[_c('Loader')],1):_c('div',[_c('FormulateForm',[_c('div',{staticClass:"flex flex-col gap-6"},[_c('div',[_c('h1',{staticClass:"text-gray-700 font-semibold text-lg p-4 bg-primary-300 HeadingPart"},[_vm._v("Configuration")]),_c('div',{staticClass:"bg-white p-6 configDetails"},[_c('div',{staticClass:"grid grid-cols-2 gap-6 configData"},[_c('FormulateInput',{staticClass:"w-full",attrs:{"label":"Retention Period (should be in Days)","placeholder":"Retention Period (should be in Days)","type":"number","validation":"required|number|min:1|max:30000|number|matches:/^[+]?\\d+$/","validation-messages":{
                                    matches: 'Retention period should be a whole number.',
                                },"element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},on:{"keypress":_vm.isIntegerNumber},model:{value:(_vm.configuration.retention_period),callback:function ($$v) {_vm.$set(_vm.configuration, "retention_period", $$v)},expression:"configuration.retention_period"}}),_c('FormulateInput',{staticClass:"w-full",attrs:{"label":"Max Users","placeholder":"Max Users","validation":"required|number|min:0,number","type":"number","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},on:{"keypress":_vm.isIntegerNumber},model:{value:(_vm.configuration.max_users),callback:function ($$v) {_vm.$set(_vm.configuration, "max_users", $$v)},expression:"configuration.max_users"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-6"},[_c('FormulateInput',{staticClass:"w-full",attrs:{"label":"Max Candidates","placeholder":"Max Candidates","validation":"required|number|min:0,number","type":"number","element-class":(context, classes) => ['flex-1 min-w-full'].concat(classes)},on:{"keypress":_vm.isIntegerNumber},model:{value:(_vm.configuration.max_candidates),callback:function ($$v) {_vm.$set(_vm.configuration, "max_candidates", $$v)},expression:"configuration.max_candidates"}}),_c('div',[_c('h2',{staticClass:"mb-1 mt-1"},[_vm._v("Dashboards *")]),_c('Dropdown',{attrs:{"placeholder":"Dashboards","options":_vm.dashboards,"multiple":"","colors":{
                                        text: '#282828',
                                        bg: 'white',
                                        svg: '#282828',
                                        border: '#bcbcbc',
                                    },"config":{ label: 'name', trackBy: 'name' },"maxWidth":"100%","minWidth":"100%","width":"102px","height":"20px"},on:{"input":(value) => _vm.setTenantDashboards(value)},model:{value:(_vm.configuration.dashboards),callback:function ($$v) {_vm.$set(_vm.configuration, "dashboards", $$v)},expression:"configuration.dashboards"}})],1)],1)])])])])],1),_c('div',{staticClass:"flex mt-4 bg-white w-full justify-between p-4 buttonPanel"},[_c('Button',{attrs:{"text":"Back","type":"outline"},on:{"click":function($event){return _vm.$emit('handleBackSection')}}}),_c('Button',{attrs:{"loader":_vm.isFormSubmit,"text":`${_vm.$route.params.tenant_id ? 'Update & Next':'Save & Next'}`,"type":"primary"},on:{"click":_vm.handleSubmit}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }