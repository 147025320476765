import SubHeader from "@/components/SubHeader";
import Forms from "./form/index";
import ClientInformationIcon from "@shared/assets/icons/tenant-client/client-information.svg"
import contactIcon from "@shared/assets/icons/tenant-client/contact.svg";
// import domainIcon from "@shared/assets/icons/tenant-client/domain.svg";
import addressIcon from "@shared/assets/icons/tenant-client/address.svg";
import financialsIcon from "@shared/assets/icons/tenant-client/financials.svg";
import configurationIcon from "@shared/assets/icons/tenant-client/configuration.svg";
import creditsIcon from "@shared/assets/icons/tenant-client/credit-pack.svg"

import {createTenant} from "./services"
import {editTenantDetails, getTenantDetails, getTenantDashboards} from "../edit-tenant/services"
import loader from "@shared/light-ray/components/loading-screen"
// import permissionsList from "./form/index"

export default {
    name: "create-client",
    components: {
        SubHeader,
        loader
    },
    created() {},
    async mounted() {
        
    
    },

    data() {
        return {
            selectedSection: "client_information",
            creatingTenant: false,
            loadingText: ["Creating Tenant, Please wait..."],
            createClientSections: [
                {
                    name: "client_information",
                    label: "Tenant Information",
                    values: {},
                    hasErrors: false,
                    disabled: false,
                    logo: ClientInformationIcon,
                    show: true,
                },
                {
                    name: "contact",
                    label: "Contact",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: contactIcon,
                    show: true,

                },
                {
                    name: "address",
                    label: "Address",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: addressIcon,
                    show: true,

                },
                {
                    name: "financial_details",
                    label: "Financials",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: financialsIcon,
                    show: true,

                },
                {
                    name: "configuration",
                    label: "Configuration",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: configurationIcon,
                    show: true,
                },
                {
                    name: "allottedfeature",
                    label: "Allotted Features",
                    values: {},
                    hasErrors: false,
                    disabled: true,
                    logo: creditsIcon,
                    show: true,
                },
            ],
            FormComponents: Forms,
            customBreadCrumbs: [],
            clientId: null,
            clientDetails: null,
            loadingStates:{
                client: false,
                formSubmit: false
            },
            // permissionsList: permissionsList,
            createTenantPayload: {
                tenant_information: {},
                contact: [],
                address: [],
                permissions: [],
            },
            tenant_address:[],
            tenant_contact:[]
            
        };
    },
    computed: {
        activeForm: {
            get() {
                return this.createClientSections.find((el) => el.name === this.selectedSection);
            },
            set(val) {
                console.log(val, ">>>val");
            },
        },
        getCreateClientSection:{
            get(){
                return this.createClientSections.map(el => {
                    if(el.name === 'packageandcheck' || el.name === 'work_allocation'){
                        return {...el, show:this.isClientOnboarded}
                    }else{
                        return el
                    }
                })
            },
            set(val){
                this.createClientSections = val
            }
        },
        isClientOnboarded(){
            return this.clientDetails?.onboarded || false
        }
    },
    methods: {
        enableAll() {
            this.createClientSections.forEach((res)=>{
                res.disabled = false
            })
        },
        checkClientOnBoarding() {
            return this.$route.name === "create-client";
        },
        checkActiveTab() {
            let active_tab = this.$route.params.tab;
            let query_data = this.$route.query;
            if(this.checkClientOnBoarding()){
                active_tab = 'client_information'
            }
            active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "client_information" }, query: query_data });
            this.selectedSection = active_tab ? active_tab : "client_information";
            this.setBredCrumbs();
        },
        sectionClick({ name, disabled }) {
            if (this.selectedSection !== name && !disabled) {
                this.selectedSection = name;
                this.$router.push({ params: { tab: name } });
                this.customBreadCrumbs = [{ name: this.selectedSection }];
                this.setBredCrumbs();
            }
        },
        async handleSubmit() {
            this.$router.push
        },
        async handleNext(params){
            let currentIndex = this.getCreateClientSection.findIndex((el) => el.name === this.selectedSection);
            if(params.key == "contact") {
                this.tenant_contact = []
                Object.entries(params).forEach(([key,value])=>{
                    if (key == "primary_contact") {
                    this.tenant_contact.push(
                        {contact_type: "Primary Contact", ...value}
                    )
                    }
                    else if (key == "accounts_contact") {
                        this.tenant_contact.push(
                            {contact_type: "Accounts Contact", ...value}
                        ) 
                    }
                    else if (key == "additional_contacts" && params.additional_contacts.length>0) {
                        let additional_contacts = {}
                        params.additional_contacts.forEach((res)=>{
                            Object.assign(additional_contacts, res)
                        })
                        this.tenant_contact.push({contact_type: "Additional Contact", ...additional_contacts})
                    }
                })
                this.createTenantPayload.contact = this.tenant_contact
            }
            else if (params.key == "address") {
                this.tenant_address = []
                Object.entries(params).forEach(([key,value])=>{
                    if (key == "primary_address") {
                        this.tenant_address.push({address_type: "Primary Address",...value})
                    }

                    else if (key == "billing_address") {
                        this.tenant_address.push({address_type: "Billing Address", ...value})
                    } else {
                        this.createTenantPayload.tenant_information.primary_address_check = params.primary_address_check;
                    }
                })
                this.createTenantPayload.address = this.tenant_address
            }
            else {
                this.createTenantPayload.tenant_information = {...this.createTenantPayload.tenant_information, ...params}
            }
            if (currentIndex < this.getCreateClientSection.length - 1) {
                let nextSec = this.getCreateClientSection[currentIndex + 1];
                this.selectedSection = nextSec.name;
                this.$router.push({ params: { tab: nextSec.name } });
                this.createClientSections.forEach((res)=>{
                    if(res.name == nextSec.name) {
                        res.disabled = false
                    }
                })
            }

            if (params.key == "allottedfeature" && !this.$route.params.tenant_id) {
                this.creatingTenant = true
                this.createTenantPayload.contact.forEach((res)=>{
                    if (res.contact_type == "Primary Contact") {
                        this.createTenantPayload.tenant_information.email = res.email
                        this.createTenantPayload.tenant_information.admin.email = res.email
                        this.createTenantPayload.tenant_information.admin.user_name = this.createTenantPayload.tenant_information.title
                    }
                })
                let final_payload = {
                    max_clients:0,
                    client_count: 0,
                    tenant_type: "6427baa5-7a47-4950-9c1d-4c0aecd065d8",
                }
                this.createTenantPayload.tenant_information = 
                {...this.createTenantPayload.tenant_information, logo_url:this.createTenantPayload.tenant_information?.logo_url ?  this.createTenantPayload.tenant_information?.logo_url[0]?.url : null, ...final_payload}
                this.createTenantPayload.permissions = params.permission
                let response = await createTenant(this.createTenantPayload);
                this.creatingTenant = false;
                if (response.success) {
                    this.$toast.success('Tenant created successfully.');
                    this.$router.push({name: "manageTenants"});
                } else if (!response.success) {
                    let errMsg = response.error.response.data.detail
                    if (Array.isArray(errMsg)) {
                        this.$toast.error(errMsg?.[0]?.msg || 'Something went wrong!');
                    } else {
                        this.$toast.error(errMsg ? errMsg : 'Something went wrong!');
                    }
                }
            }
           if (params.key == "allottedfeature" && this.$route.params.tenant_id) {
                const {data} = await getTenantDetails(this.$route.params.tenant_id)
                const dashboardData = await getTenantDashboards(this.$route.params.tenant_id)
                let currentDashboard = []
                if (params?.dashboards?.length > 0) {
                    currentDashboard = params?.dashboards
                } else if(dashboardData?.data?.dashboards) {
                    dashboardData.data.dashboards.forEach((res)=>{
                        let dashId = res.id
                        res.id = res.dashboard_id
                        res.dashboard_id = dashId
                    })
                    currentDashboard = dashboardData.data.dashboards
                } 
                this.createTenantPayload.address = this.tenant_address
                this.createTenantPayload.tenant_information = {...data, ...this.createTenantPayload.tenant_information, logo_url:this.createTenantPayload.tenant_information?.logo_url ?  this.createTenantPayload.tenant_information?.logo_url[0]?.url : null, dashboards: currentDashboard} 
                this.createTenantPayload.permissions = params.permission
                await editTenantDetails(this.createTenantPayload, this.$route.params.tenant_id)
                this.$toast.success('Tenant updated successfully.')
                this.$router.push({name: "manageTenants"});
            }
        },
        handleBackSection() {
            let currentIndex = this.getCreateClientSection.findIndex((el) => el.name === this.selectedSection);
            if (currentIndex > 0) {
                let prevSec = this.getCreateClientSection[currentIndex - 1];
                this.selectedSection = prevSec.name;
                this.$router.push({ params: { tab: prevSec.name } });
            }
        },
        // isSectionDisable() {
        //     return !this.createClientSections.find((el) => el.name === this.selectedSection);
        // },
        setBredCrumbs(crumb) {
            this.customBreadCrumbs = [];
            let selectedKey = this.getCreateClientSection.find((el) => el.name === this.selectedSection);
            this.customBreadCrumbs = [{ name: selectedKey.label.split("_").join(" ") }];
            if (crumb) {
                this.customBreadCrumbs.push({ name: crumb });
            }
        },
    },
};
